export default function Travronden() {
  return (
    <svg
      x="0px"
      y="0px"
      width="280"
      height="24"
      viewBox="25 0 127 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Travronden logotyp</title>
      <g
        id="Group-15"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(20.000000, 0.000000)"
      >
        <polygon
          id="Fill-170"
          fill="#525252"
          points="9.06060601 4.94786667 9.0618938 3.22086667 8.18329544 3.22086667 8.18329544 8.5042 9.62466036 8.5012 9.62466036 11.3738667 2.16188641 11.3738667 2.16188641 8.5042 3.57234425 8.5042 3.57234425 3.22086667 2.71016528 3.22086667 2.71241892 4.94786667 0 4.94786667 0 0.365866667 11.9127507 0.365866667 11.9127507 4.94786667"
        ></polygon>
        <path
          d="M22.2615062,11.6034667 C18.3041111,11.6054667 19.230036,8.22813333 18.5362363,7.21346667 C18.292521,6.85713333 17.8498413,6.90946667 17.8498413,6.90946667 L17.8417926,11.3741333 L12.0496112,11.3741333 L12.0496112,8.50413333 L13.2549876,8.50413333 L13.2517681,3.22146667 L12.591773,3.2228 L12.5908072,0.3658 L20.2451407,0.3658 C22.2093505,0.3658 24.622357,0.923466667 24.622357,3.41613333 C24.622357,5.59713333 22.7019322,6.2368 20.7534979,6.2368 C21.5773649,6.33513333 22.1475363,6.39013333 22.6884104,6.92646667 C23.5486576,7.7798 23.0061739,9.1328 23.546082,9.12613333 C23.792051,9.12346667 23.628179,8.30713333 23.5805306,7.9298 L24.5083871,7.9298 C24.7910582,9.2368 24.5611867,11.6021333 22.2615062,11.6034667 M18.8356487,3.1538 L17.8656168,3.1538 L17.8656168,5.3348 L18.7564493,5.3348 C19.6434183,5.3348 20.0394154,5.02346667 20.0394154,4.23613333 C20.0394154,3.43246667 19.7383932,3.1538 18.8356487,3.1538"
          id="Fill-171"
          fill="#525252"
        ></path>
        <path
          d="M31.1685104,11.374 L31.1685104,8.50433333 L32.3185117,8.50433333 L31.9585729,7.46833333 L29.1486034,7.46833333 L28.7980012,8.50433333 L30.1389181,8.50433333 L30.1389181,11.374 L24.7182656,11.374 L27.7149653,3.221 L26.4120384,3.221 L26.4120384,0.333333333 L34.1001765,0.333333333 L37.1097542,8.50433333 L38.1106931,8.50433333 L38.1106931,11.374 L31.1685104,11.374 Z M30.5391005,3.37866667 L29.6505217,5.99433333 L31.4450645,5.99433333 L30.5391005,3.37866667 Z"
          id="Fill-173"
          fill="#525252"
        ></path>
        <polygon
          id="Fill-174"
          fill="#525252"
          points="47.768321 3.21936667 44.2043475 11.3740333 40.6564713 11.3740333 37.0609467 3.21936667 36.1704363 3.21936667 36.1704363 0.3657 42.7166219 0.3657 42.7166219 3.21936667 41.702805 3.21936667 43.2391449 6.7287 44.7123827 3.21936667 43.7143413 3.21936667 43.7143413 0.3657 48.5368129 0.3657 48.5368129 3.21936667"
        ></polygon>
        <g
          id="Group-12"
          transform="translate(49.258171, 0.000000)"
          fill="#525252"
        >
          <path
            d="M10.6456958,11.5697801 C6.37248197,11.5697801 7.82620442,7.78505093 7.04126067,6.93462895 C6.69940357,6.55685886 5.95760684,6.60605683 5.5128607,6.60605683 L5.35022965,6.60605683 L5.35022965,8.6003315 L5.95096884,8.6003315 L5.95096884,11.3501464 L0,11.3501464 L0,8.6003315 L0.869578268,8.6003315 L0.869578268,3.07083159 L0,3.07083159 L0,0.336830357 L8.03530148,0.336830357 C9.87402805,0.336830357 12.1143537,0.870979698 12.1143537,3.26059517 C12.1143537,5.34975163 10.5112762,5.96296913 8.68748518,5.96296913 C9.45915292,6.05609385 10.1113366,6.18260291 10.7651798,6.71499518 C11.6845431,7.45296466 11.312815,9.213549 12.0562712,9.213549 C12.5142934,9.213549 12.5740354,8.41232499 12.5308884,8.05036853 L13.5382052,8.05036853 C13.5382052,8.25418867 13.5680762,8.45800882 13.5680762,8.66358603 C13.5680762,10.6438041 12.4694868,11.5697801 10.6456958,11.5697801 M6.71433907,3.00933413 L5.36516516,3.00933413 L5.36516516,5.09673353 L6.63966155,5.09673353 C7.47107131,5.09673353 7.84279942,4.79803159 7.84279942,4.04600555 C7.84279942,3.27465173 7.56068433,3.00933413 6.71433907,3.00933413"
            id="Fill-175"
          ></path>
          <path
            d="M19.7844884,11.4350129 C16.5351864,11.4350129 13.6576125,9.51980634 13.6576125,5.79657459 C13.6576125,1.99603175 16.4173619,0 19.7844884,0 C23.211357,0 25.9561708,1.94859085 25.9561708,5.82820185 C25.9561708,9.61468813 23.0487259,11.4350129 19.7844884,11.4350129 M19.7844884,2.48274019 C18.2560885,2.48274019 18.271024,4.54026939 18.271024,5.68763623 C18.271024,6.91231416 18.1980059,8.9382161 19.8143594,8.9382161 C21.4025014,8.9382161 21.3576949,6.89650053 21.3576949,5.7016928 C21.3576949,4.4453876 21.4307129,2.48274019 19.7844884,2.48274019"
            id="Fill-177"
          ></path>
          <polygon
            id="Fill-180"
            points="38.3494057 3.07083159 38.3494057 11.3501464 35.4867673 11.3501464 30.5613698 6.46549121 30.5613698 8.59857443 31.5935791 8.59857443 31.5935791 11.3501464 26.6648626 11.3501464 26.6648626 8.59857443 27.5195054 8.59857443 27.5195054 3.07083159 26.6947336 3.07083159 26.6947336 0.336830357 30.6642588 0.336830357 35.7390114 5.17580169 35.7390114 3.07083159 34.7366731 3.07083159 34.7366731 0.336830357 39.278726 0.336830357 39.278726 3.07083159"
          ></polygon>
          <path
            d="M47.8216771,11.3501464 L40.6476564,11.3501464 L40.6476564,8.6003315 L41.5919122,8.6003315 L41.5919122,3.07083159 L40.6476564,3.07083159 L40.6476564,0.336830357 L48.2216167,0.336830357 C51.4709187,0.336830357 54.1559906,2.34867573 54.1559906,6.00865296 C54.1559906,9.93570485 51.0560436,11.3501464 47.8216771,11.3501464 M47.659046,3.00933413 L46.0709041,3.00933413 L46.0709041,8.67764259 L47.4350135,8.67764259 C49.157575,8.67764259 49.3948836,7.35983994 49.3948836,5.85227371 C49.3948836,4.62759578 49.157575,3.00933413 47.659046,3.00933413"
            id="Fill-181"
          ></path>
          <path
            d="M54.9556314,11.3501464 L54.9556314,8.6003315 L55.8252097,8.6003315 L55.8252097,3.07083159 L54.9556314,3.07083159 L54.9556314,0.336830357 L66.7878701,0.336830357 L66.8028056,5.01415123 L63.8356187,5.01415123 L63.8057477,3.07083159 L60.3788791,3.07083159 L60.3821981,4.79803159 L63.123693,4.79803159 L63.123693,7.06289508 L60.3639436,7.06289508 L60.3639436,8.6003315 L63.9999092,8.6003315 C64.0131852,7.89398928 64.0297802,7.63394289 64.0131852,6.9258436 L67.0550497,6.9258436 L67.0550497,11.3501464 L54.9556314,11.3501464 Z"
            id="Fill-183"
          ></path>
          <polygon
            id="Fill-186"
            points="79.9726058 3.07083159 79.9726058 11.3501464 77.1099674 11.3501464 72.1845699 6.46549121 72.1845699 8.59857443 73.2151197 8.59857443 73.2151197 11.3501464 68.2880627 11.3501464 68.2880627 8.59857443 69.1427055 8.59857443 69.1427055 3.07083159 68.3179337 3.07083159 68.3179337 0.336830357 72.287459 0.336830357 77.3622115 5.17580169 77.3622115 3.07083159 76.3598732 3.07083159 76.3598732 0.336830357 80.9019261 0.336830357 80.9019261 3.07083159"
          ></polygon>
        </g>
      </g>
    </svg>
  )
}
